import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { SharedService } from "../../services";
import { Location } from "@angular/common";
@Component({
  selector: "app-first",
  templateUrl: "./first.component.html",
  styleUrls: ["./first.component.scss"],
})
export class FirstComponent implements OnInit {
  loading = false;
  ebookImages = [];
  packageImages = [];
  softwareImages = [];
  hyperlink = [];
  groupedEbookImages: any[] = [];
  groupedPackageImages: any[] = [];
  groupedSoftwareImages: any[] = [];

  // To track which images to show (only 6 images at a time)
  currentEbookIndex: number = 0;
  currentPackageIndex: number = 0;
  currentSoftwareIndex: number = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private shared: SharedService,
    private location: Location
  ) {}

  ngOnInit() {
    this.fetchCarouselImages();
  }

  fetchCarouselImages() {
    this.loading = true;
    this.http
      .get(
        "https://coasttocoastofficesupply.com/admin/public/api/v1/sliding-image-data"
      )
      .subscribe(
        (response: any) => {
          console.log(response, "this is first df");
          this.loading = false;
          this.ebookImages = response.success.ebook_images;
          this.packageImages = response.success.package_images;
          this.softwareImages = response.success.software_images;

          console.log(this.hyperlink, "some be honest");
          this.groupImages();
        },
        (error) => {
          this.loading = false;
          console.error("Error fetching carousel images:", error);
        }
      );
  }

  groupImages() {
    this.groupedEbookImages = this.ebookImages;
    this.groupedPackageImages = this.packageImages;
    this.groupedSoftwareImages = this.softwareImages;
  }

  nextEbookImages() {
    if (this.ebookImages.length > 0) {
      this.currentEbookIndex = this.ebookImages.length - 6; // Last 6 images ke starting index
      this.groupedEbookImages = this.ebookImages.slice(this.currentEbookIndex); // Last 6 images
    }
  }

  prevEbookImages() {
    if (this.ebookImages.length > 0) {
      this.currentEbookIndex = 0;
      this.groupedEbookImages = this.ebookImages.slice(0, 6); // Pehli 6 images
    }
  }

  nextPackageImages() {
    if (this.packageImages.length > 0) {
      this.currentPackageIndex = Math.max(this.packageImages.length - 6, 0);
      this.groupedPackageImages = this.packageImages.slice(
        this.currentPackageIndex
      );
    }
  }

  prevPackageImages() {
    if (this.packageImages.length > 0) {
      this.currentPackageIndex = 0;
      this.groupedPackageImages = this.packageImages.slice(0, 6);
    }
  }

  nextSoftwareImages() {
    if (this.softwareImages.length > 0) {
      this.currentSoftwareIndex = Math.max(this.softwareImages.length - 6, 0);
      this.groupedSoftwareImages = this.softwareImages.slice(
        this.currentSoftwareIndex
      );
    }
  }

  prevSoftwareImages() {
    if (this.softwareImages.length > 0) {
      this.currentSoftwareIndex = 0;
      this.groupedSoftwareImages = this.softwareImages.slice(0, 6);
    }
  }

  getImageUrl(imagePath: string): string {
    return `https://coasttocoastofficesupply.com/admin/public${imagePath}`;
  }
  // onScroll(event: any, type: string) {
  //   let target = event.target;

  //   // Check if user has scrolled to the bottom
  //   if (target.scrollTop + target.clientHeight >= target.scrollHeight - 10) {
  //     if (
  //       type === "ebook" &&
  //       this.currentEbookIndex + 1 < this.ebookImages.length
  //     ) {
  //       this.currentEbookIndex += 2;
  //       this.groupImages();
  //     } else if (
  //       type === "package" &&
  //       this.currentPackageIndex + 1 < this.packageImages.length
  //     ) {
  //       this.currentPackageIndex += 2;
  //       this.groupImages();
  //     } else if (
  //       type === "software" &&
  //       this.currentSoftwareIndex + 1 < this.softwareImages.length
  //     ) {
  //       this.currentSoftwareIndex += 2;
  //       this.groupImages();
  //     }
  //   }

  // }

  productCategory(value: string) {
    console.log("value", value);
    localStorage.setItem("selectedCategories", JSON.stringify([value]));
    this.router.navigate([
      "category/downloadable",
      { category_id: value, first_page: "true" },
    ]);
  }

  productCategoryid(value: string) {
    console.log("value is vale", value);
    localStorage.setItem("selectedCategories", JSON.stringify([value]));
    this.router.navigate([
      "category/ebook",
      { category_id: value, first_page: "true" },
    ]);
  }

  productCategoryname(value: string) {
    console.log(value, "print the value");
    localStorage.setItem("selectedCategories", JSON.stringify([value]));
    this.router.navigate([
      "category/physical-products",
      { category_id: value, first_page: "true" },
    ]);
  }
}
